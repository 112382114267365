const WIDGET_URL = process.env.MIX_PROFESSIONALS_WIDGET_URL || 'https://widget.psicologiaymente.com/professionals/:id';

!(function () {
  const anchor = document.querySelector('#pym-pro-url');
  const { professionalId } = anchor.dataset;
  const IFRAME_SRC = WIDGET_URL.replace(':id', professionalId);
  const template = `<iframe src="${IFRAME_SRC}" width="288" height="170" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" allowtransparency="true"></iframe>`;

  const wrapper = document.createElement('div');
  wrapper.innerHTML = template;
  anchor.parentNode.replaceChild(wrapper, anchor);
})();
